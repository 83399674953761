import React, { useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useSortableData from "../../hooks/useSortableData";
import { Tooltip } from "react-tooltip";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

const SalesHistoryreports = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);
  const [revenueCategory, setRevenueCategory] = useState([]);
  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalGst, setTotalGst] = useState("");
  const [totalNetPrice, setTotalNetPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  // summary
  const [summaryTotalDiscount, setSummaryTotalDiscount] = useState("");
  const [summaryTotalGst, setSummaryTotalGst] = useState("");
  const [summaryTotalNetPrice, setsummaryTotalNetPrice] = useState("");
  const [summaryTotalPrice, setSummaryTotalPrice] = useState("");

  //adeel fetched role to check the role of the user and add checks accordingly
  const { role } = state;
  // adeel - end

  const date = new Date();

  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myNames, setMyNamesa] = useState([]);
  // const [totalResults, setTotalResults] = useState("0");
  // const [totalPages, setTotalPages] = useState("1");
  const [option, setOption] = useState("");
  // const [limit, setLimit] = useState("10");
  // const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);
        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };

  const getRevenueCategory = async (pkg_id) => {
    setDAteSeted(false);
    try {
      const response = await UserServices.RevenueCategoryNames(
        pkg_id,
        branch_id
      );

      const res = response.data.data;
      const results = [];
      if (response.status === 200) {
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setRevenueCategory([{ name: "All", id: "" }, ...results]);
      }
    } catch (err) {
      setRevenueCategory([{ name: "All", id: "" }]);
      console.log(err);
    }
  };

  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);

      try {
        if (radioOption === "Detail") {
          setMyData([]);
          setMyNamesa([]);
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/orders-detail/export?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.report +
              "&package_id=" +
              values.revenueCat +
              "&category=" +
              values.package_type +
              "&report_type=detail"
          );

          // start_date: values.sdate,
          // end_date: values.edate,
          // gender: values.report,
          // package_id: values.revenueCat,
          // category: values.package_type,

          const responce = await UserServices.getDetailSaleReport(
            branch_id,
            values
          );
          let myDataarr;
          let myNamesarr;
          let myCafearr;

          if (responce.status === 200) {
            myDataarr = responce.data.data.detail;
            myNamesarr = responce.data.data.name_list;
            myCafearr = responce.data.data.cafe_total;

            const res = myDataarr;
            const nam = myNamesarr;
            const cafe = myCafearr;
            const results = [];
            const namesList = [];

            res.map((value) => {
              results.push({
                id: value.id,
                client_id: value.client_id,
                client_name: value.client_name,
                package_name: value.package_name,
                sale_type: value.sale_type,
                price: parseInt(value.price),
                discount: parseInt(value.discount),
                net_price: parseInt(value.net_price),
                sale_date: value.sale_date,
                // sale_type: value.sale_type,
                category: value.category,
                note: value.note,
                tax: value.tax,
              });
              return null;
            });

            nam.map((value) => {
              namesList.push({
                id: value.id,
                name: value.name,
                category: value.category,
              });
              return null;
            });

            cafe.map((value) => {
              setTotalDiscount(parseInt(value.total_discount));
              setTotalGst(parseInt(value.total_gst));
              setTotalNetPrice(parseInt(value.total_net_price));
              setTotalPrice(parseInt(value.total_price));
            });

            setMyData([...results]);
            setMyNamesa([...namesList]);

            setShow(true);
            setOption("Detail");
            setLoading(false);
          }
        } else {
          setMyData([]);
          setMyNamesa([]);

          setExportLink(
            process.env.REACT_APP_API_Link +
              "/orders-detail/export?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.report +
              "&package_id=" +
              values.revenueCat +
              "&category=" +
              values.package_type +
              "&report_type=summery"
          );
          const responce = await UserServices.getSummerySaleReport(
            branch_id,
            values
          );
          let myDataarr;
          let myNamesarr;
          let myCafearr;

          if (responce.status === 200) {
            myDataarr = responce.data.data.summery;
            myNamesarr = responce.data.data.name_list;
            myCafearr = responce.data.data.cafe_total;

            const res = myDataarr;
            const nam = myNamesarr;
            const cafe = myCafearr;
            const results = [];
            const namesList = [];

            res.map((value) => {
              results.push({
                package_name: value.package_name,
                sale_type: value.sale_type,
                price: parseInt(value.total_price),
                discount: parseInt(value.total_discount),
                net_price: parseInt(value.total_net),
                totalQuantity: value.totalQuantity,
                category: value.category,
                package_id: value.package_id,
                tax: parseInt(value.tax),
              });
              return null;
            });

            nam.map((value) => {
              namesList.push({
                id: value.id,
                name: value.name,
                category: value.category,
              });
              return null;
            });

            cafe.map((value) => {
              setSummaryTotalDiscount(parseInt(value.total_discount));
              setSummaryTotalGst(parseInt(value.total_gst));
              setsummaryTotalNetPrice(parseInt(value.total_net_price));
              setSummaryTotalPrice(parseInt(value.total_price));
            });
            setMyData([...results]);
            setMyNamesa([...namesList]);

            setShow(true);
            setOption("Summary");
            // setLimit(responce.data.data.per_page);
            // setTotalResults(responce.data.totalRecord);
            // setTotalPages(responce.data.totalPages);
            // setCurrentPage(responce.data.data.current_page);
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);

          setLoading(false);
        } else {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    report: "",
    revenueCat: "",
    package_type: "",
  };

  const Table = ({ data, name }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    let total_price = 0;
    let total_discount = 0;
    let total_tax = 0;
    let total_net_price = 0;
    let recordOrder = 0;

    const generatePdf = () => {
      const doc = new jsPDF("p", "mm", "letter");
      const tables = document.querySelectorAll("table");

      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 },
      };
      let currentY = 10;
      let pageWidth = doc.internal.pageSize.width;
      let pageHeight = doc.internal.pageSize.height;
      tables.forEach((table, index) => {
        const captionText = table.getAttribute("data-caption");
        let tableHeight = doc.autoTable.previous.finalY - currentY;
        if (currentY + tableHeight + 20 > pageHeight) {
          doc.addPage();
          currentY = 10;
        }

        doc.text(captionText, 10, currentY);
        currentY += 5;

        doc.autoTable({
          html: table,
          startY: currentY,
          ...tableOptions,
        });
        const tableBottomY = doc.autoTable.previous.finalY + 5;
        const lineWidth = pageWidth - 20;
        doc.setDrawColor(0);
        doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
        currentY = tableBottomY + 10;
      });
      doc.save("sales-by-services.pdf");
    };

    return option === "Detail" ? (
      <div id="report-POS">
        {name.length ? (
          <>
            <div className="col-md-12 mt-2">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="mb-0 text-uppercase">Filtered Result</h5>
                </div>

                <div className="col-12 col-md-4 ms-auto text-end">
                  <button
                    className="btn btn-primary mx-2 file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                  <a
                    href={exportLink}
                    rel="noreferrer"
                    // target="_blank"
                    className="btn btn-primary file-download"
                  >
                    <i className="bi bi-download" /> Excel
                  </a>
                </div>
              </div>
              <hr />
            </div>
            {name.map((nel, index) => (
              <div className="card" key={index}>
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">
                    {nel.name !== null ? nel.name : "Guest Pass"}
                  </h5>
                </div>
                <span className="d-none">{(total_price = 0)}</span>
                <span className="d-none">{(total_discount = 0)}</span>
                <span className="d-none">{(total_tax = 0)}</span>
                <span className="d-none">{(total_net_price = 0)}</span>
                <span className="d-none">{(recordOrder = 0)}</span>

                <div className="card-body">
                  <div className="table-responsive ">
                    <table
                      className="table  table-borderless table-style-1"
                      data-caption={nel.name !== null ? nel.name : "Guest Pass"}
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="no_sort">
                            Sr#
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("package_name")}
                            className={getClassNamesFor("package_name")}
                          >
                            Item Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("client_name")}
                            className={getClassNamesFor("client_name")}
                          >
                            Client Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("price")}
                            className={getClassNamesFor("price")}
                          >
                            Price
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("discount")}
                            className={getClassNamesFor("discount")}
                          >
                            Discount
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("tax")}
                            className={getClassNamesFor("tax")}
                          >
                            GST
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("sale_date")}
                            className={getClassNamesFor("sale_date")}
                          >
                            Purchase Date
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("sale_type")}
                            className={getClassNamesFor("sale_type")}
                          >
                            Sale Type
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("net_price")}
                            className={getClassNamesFor("net_price")}
                          >
                            Net Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length ? (
                          items.map((el, index) =>
                            nel.name === el.package_name ? (
                              <tr key={index}>
                                <td className="d-none">
                                  {(total_price = total_price + el.price)}
                                </td>
                                <td className="d-none">
                                  {
                                    (total_discount =
                                      total_discount + el.discount)
                                  }
                                </td>
                                <td className="d-none">
                                  {(total_tax = total_tax + el.tax)}
                                </td>
                                <td className="d-none">
                                  {
                                    (total_net_price =
                                      total_net_price + el.net_price)
                                  }
                                </td>
                                <td className="d-none">
                                  {(recordOrder = recordOrder + 1)}
                                </td>

                                <td>{recordOrder}</td>
                                <td>
                                  {el.package_name !== null
                                    ? el.package_name
                                    : "Guest Pass"}

                                  {el.note === "" ? null : (
                                    <>
                                      <i
                                        className="bi bi-info-square note-here"
                                        data-tooltip-id={el.id}
                                        data-tooltip-content={el.note}
                                      ></i>
                                      <Tooltip
                                        id={el.id}
                                        place="right"
                                        className="note-tooltip"
                                        clickable
                                      />
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Link to={"/client-home/" + el.client_id}>
                                    {el.client_name}
                                  </Link>
                                </td>
                                <td>
                                  <CurrencyFormat
                                    value={el.price}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                                <td>
                                  <CurrencyFormat
                                    value={el.discount}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                                <td>
                                  <CurrencyFormat
                                    value={el.tax}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>

                                {/* <td>{el.sale_date}</td> */}
                                <td>
                                  {moment(el.sale_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{el.sale_type}</td>
                                <td>
                                  <CurrencyFormat
                                    value={el.net_price}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="3"></td>
                          <td colSpan="1" className="expenses-num">
                            Total Price :{" "}
                            <CurrencyFormat
                              value={total_price}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td colSpan="1" className="expenses-num">
                            Total Discount :{" "}
                            <CurrencyFormat
                              value={total_discount}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td colSpan="1" className="expenses-num">
                            Total GST :{" "}
                            <CurrencyFormat
                              value={total_tax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>

                          <td colSpan="1" className="expenses-num">
                            Total Net Price :{" "}
                            <CurrencyFormat
                              value={total_net_price}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}

            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Cafe Sales</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive ">
                  <table
                    className="table  table-borderless table-style-1"
                    data-caption="Cafe Sales"
                  >
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort">
                          Sr#
                        </th>
                        <th scope="col" className="no_sort">
                          Item Name
                        </th>
                        <th scope="col" className="no_sort">
                          Price
                        </th>

                        <th scope="col" className="no_sort">
                          Discount
                        </th>
                        <th scope="col" className="no_sort">
                          GST
                        </th>
                        <th scope="col" className="no_sort">
                          Net Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Cafe</td>
                        <td>
                          <CurrencyFormat
                            value={totalPrice}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={totalDiscount}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={totalGst}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={totalNetPrice}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <table
              className="table  table-borderless table-style-1 d-none"
              data-caption="Totals"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Total Price{" "}
                  </th>
                  <th scope="col" className="no_sort">
                    Total Discount
                  </th>
                  <th scope="col" className="no_sort">
                    Total GST{" "}
                  </th>
                  <th scope="col" className="no_sort">
                    Total Net Price{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CurrencyFormat
                      value={
                        myData.reduce((a, v) => (a = a + v.price), 0) +
                        totalPrice
                      }
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={
                        myData.reduce((a, v) => (a = a + v.discount), 0) +
                        totalDiscount
                      }
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={
                        myData.reduce((a, v) => (a = a + v.tax), 0) + totalGst
                      }
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={
                        myData.reduce((a, v) => (a = a + v.net_price), 0) +
                        totalNetPrice
                      }
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              {/* <div className="col-4">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Total Price{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.price), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Discount{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total GST{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Net Price{" "}
                      <CurrencyFormat
                        value={myData.reduce(
                          (a, v) => (a = a + v.net_price),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Cafe Total Price{" "}
                      <CurrencyFormat
                        value={totalPrice}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Cafe Total Discount{" "}
                      <CurrencyFormat
                        value={totalDiscount}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Cafe Total GST{" "}
                      <CurrencyFormat
                        value={totalGst}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Cafe Total Net Price{" "}
                      <CurrencyFormat
                        value={totalNetPrice}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/*//? Total */}
              <div className="col-4 ms-auto">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Total Price{" "}
                      <CurrencyFormat
                        // value={
                        //   myData.reduce((a, v) => (a = a + v.price), 0) +
                        //   totalPrice
                        // }
                        value={myData.reduce((a, v) => (a = a + v.price), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Discount{" "}
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.discount), 0) +
                          totalDiscount
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total GST{" "}
                      <CurrencyFormat
                        // value={
                        //   myData.reduce((a, v) => (a = a + v.tax), 0) + totalGst
                        // }
                        value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Net Price{" "}
                      <CurrencyFormat
                        // value={
                        //   myData.reduce((a, v) => (a = a + v.net_price), 0)
                        // }
                        // value={
                        //   myData.reduce((a, v) => (a = a + v.net_price), 0) +
                        //   totalNetPrice
                        // }
                        value={myData.reduce(
                          (a, v) => (a = a + v.net_price),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-header py-3 bg-white text-danger">
              <h5 className="mb-0 font-20">No record found</h5>
            </div>
          </div>
        )}

        {/* {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )} */}
      </div>
    ) : option === "Summary" ? (
      <>
        {name.length ? (
          <>
            <a
              href={exportLink}
              rel="noreferrer"
              // target="_blank"
              className="btn btn-primary mx-2 file-download float-end mb-3"
            >
              <i className="bi bi-download" /> Excel
            </a>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            {name.map((nel, index) => (
              <div className="card" key={index}>
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">
                    {nel.category === "1"
                      ? "Gym"
                      : nel.category === "2"
                      ? "Personal Trainer"
                      : nel.category === "3"
                      ? "Guest"
                      : nel.category === "4"
                      ? "Small Group PT"
                      : nel.category === "5"
                      ? "Nutritionist"
                      : nel.category === "6"
                      ? "Registration"
                      : nel.category === "7"
                      ? "Bootcamp"
                      : nel.category === "8"
                      ? "Freezing"
                      : nel.category === "9"
                      ? "General"
                      : nel.category === "11"
                      ? "CFT"
                      : nel.category === "12"
                      ? "LesMills"
                      : nel.category === "13"
                      ? "CafeDeposits"
                      : nel.category === "14"
                      ? "Physiotherapy Package"
                      : nel.category === "16"
                      ? "Befit"
                      : nel.category === "15"
                      ? "GX Package"
                      : null}
                  </h5>
                </div>
                <span className="d-none">{(total_price = 0)}</span>

                <span className="d-none">{(total_discount = 0)}</span>
                <span className="d-none">{(total_tax = 0)}</span>
                <span className="d-none">{(total_net_price = 0)}</span>
                <span className="d-none">{(recordOrder = 0)}</span>

                <div className="card-body">
                  <div className="table-responsive ">
                    <table
                      className="table  table-borderless table-style-1"
                      data-caption={
                        nel.category === "1"
                          ? "Gym"
                          : nel.category === "2"
                          ? "Personal Trainer"
                          : nel.category === "3"
                          ? "Guest"
                          : nel.category === "4"
                          ? "Small Group PT"
                          : nel.category === "5"
                          ? "Nutritionist"
                          : nel.category === "6"
                          ? "Registration"
                          : nel.category === "7"
                          ? "Bootcamp"
                          : nel.category === "8"
                          ? "Freezing"
                          : nel.category === "9"
                          ? "General"
                          : nel.category === "11"
                          ? "CFT"
                          : nel.category === "12"
                          ? "LesMills"
                          : nel.category === "13"
                          ? "CafeDeposits"
                          : nel.category === "14"
                          ? "Physiotherapy Package"
                          : null
                      }
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="no_sort">
                            Sr#
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("package_name")}
                            className={getClassNamesFor("package_name")}
                          >
                            Item Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("sale_type")}
                            className={getClassNamesFor("sale_type")}
                          >
                            Sale Type
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("totalQuantity")}
                            className={getClassNamesFor("totalQuantity")}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("price")}
                            className={getClassNamesFor("price")}
                          >
                            Price
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("discount")}
                            className={getClassNamesFor("discount")}
                          >
                            Discount
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("tax")}
                            className={getClassNamesFor("tax")}
                          >
                            GST
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("net_price")}
                            className={getClassNamesFor("net_price")}
                          >
                            Net Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length ? (
                          items.map((el, index) =>
                            nel.category === el.category ? (
                              <tr key={index}>
                                <td className="d-none">
                                  {(total_price = total_price + el.price)}
                                </td>
                                <td className="d-none">
                                  {
                                    (total_discount =
                                      total_discount + el.discount)
                                  }
                                </td>
                                <td className="d-none">
                                  {(total_tax = total_tax + el.tax)}
                                </td>
                                <td className="d-none">
                                  {
                                    (total_net_price =
                                      total_net_price + el.net_price)
                                  }
                                </td>
                                <td className="d-none">
                                  {(recordOrder = recordOrder + 1)}
                                </td>
                                <td>{recordOrder}</td>
                                <td>
                                  {el.package_name !== null
                                    ? el.package_name
                                    : "Guest Pass"}
                                </td>
                                <td>{el.sale_type}</td>

                                <td>{el.totalQuantity}</td>
                                <td>
                                  <CurrencyFormat
                                    value={el.price}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                                <td>
                                  <CurrencyFormat
                                    value={el.discount}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>

                                <td>
                                  <CurrencyFormat
                                    value={el.tax}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                                <td>
                                  <CurrencyFormat
                                    value={el.net_price}
                                    displayType={"text"}
                                    thousandSpacing={"3"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="3"></td>
                          <td colSpan="1" className="expenses-num">
                            Total Price :{" "}
                            <CurrencyFormat
                              value={total_price}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td colSpan="1" className="expenses-num">
                            Total Discount :{" "}
                            <CurrencyFormat
                              value={total_discount}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td colSpan="1" className="expenses-num">
                            Total GST :{" "}
                            <CurrencyFormat
                              value={total_tax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>

                          <td colSpan="1" className="expenses-num">
                            Total Net Price :{" "}
                            <CurrencyFormat
                              value={total_net_price}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Cafe Sales</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive ">
                  <table
                    className="table  table-borderless table-style-1"
                    data-caption="Cafe Sales"
                  >
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort">
                          Sr#
                        </th>
                        <th scope="col" className="no_sort">
                          Item Name
                        </th>
                        <th scope="col" className="no_sort">
                          Price
                        </th>

                        <th scope="col" className="no_sort">
                          Discount
                        </th>
                        <th scope="col" className="no_sort">
                          GST
                        </th>
                        <th scope="col" className="no_sort">
                          Net Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Cafe</td>
                        <td>
                          <CurrencyFormat
                            value={summaryTotalPrice}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={summaryTotalDiscount}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={summaryTotalGst}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={summaryTotalNetPrice}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <table
              className="table  table-borderless table-style-1 d-none"
              data-caption="Totals"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Total Price{" "}
                  </th>
                  <th scope="col" className="no_sort">
                    Total Discount{" "}
                  </th>
                  <th scope="col" className="no_sort">
                    Total GST{" "}
                  </th>
                  <th scope="col" className="no_sort">
                    Total Net Price{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.net_price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              {/* <div className="col-4">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Total Price{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.price), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Discount{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total GST{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Net Price{" "}
                      <CurrencyFormat
                        value={myData.reduce(
                          (a, v) => (a = a + v.net_price),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Cafe Total Price{" "}
                      <CurrencyFormat
                        value={summaryTotalPrice}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Cafe Total Discount{" "}
                      <CurrencyFormat
                        value={summaryTotalDiscount}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total GST{" "}
                      <CurrencyFormat
                        value={summaryTotalGst}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Net Price{" "}
                      <CurrencyFormat
                        value={summaryTotalNetPrice}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-4 ms-auto">
                <div className="grand-total-cart-1 bg-white card">
                  <div>
                    <div className="cart-entry">
                      Total Price{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.price), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Discount{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total GST{" "}
                      <CurrencyFormat
                        value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                    <div className="cart-entry">
                      Total Net Price{" "}
                      <CurrencyFormat
                        value={myData.reduce(
                          (a, v) => (a = a + v.net_price),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-header py-3 bg-white text-danger">
              <h5 className="mb-0 font-20">No record found</h5>
            </div>
          </div>
        )}
      </>
    ) : null;
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Sales By Services</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-danger">Reports</span> » Sales History
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                {/* //adeel added a check to only slelect start date of 3 months ago only */}
                {role === "4" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Start date</label>
                      <input
                        type="date"
                        className={`form-control ${DAteSeted ? "glow" : ""}`}
                        placeholder="Enter Start date"
                        name="sdate"
                        value={values.sdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        max={new Date().toISOString().split("T")[0]} // Today's date
                        min={
                          new Date(
                            new Date().setMonth(new Date().getMonth() - 6)
                          )
                            .toISOString()
                            .split("T")[0]
                        } // 3 months ago
                      />
                      {errors.sdate && touched.sdate ? (
                        <p className="text-danger">{errors.sdate}</p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Start date</label>
                      <input
                        type="date"
                        className={`form-control ${DAteSeted ? "glow" : ""}`}
                        placeholder="Enter Start date"
                        name="sdate"
                        value={values.sdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.sdate && touched.sdate ? (
                        <p className="text-danger">{errors.sdate}</p>
                      ) : null}
                    </div>
                  </div>
                )}
                {/* // adeel added a check to only slelect end date of 3 months ago only for sales account having role 4 */}
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Filter By Gender</label>
                    <select
                      className="form-select"
                      name="report"
                      value={values.report}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">All</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Others</option>
                    </select>
                    {/* <span className="text-danger">error message</span> */}
                    {errors.report && touched.report ? (
                      <p className="text-danger">{errors.report}</p>
                    ) : null}
                  </div>
                </div>
                {/* //adeel added check that if it is sales login that he cannot generte report more then 3 months before */}
                {role === "3" || role === "1" || role === "4" ? (
                  <>
                    <div className="col-12">
                      <h5>Quick Dates</h5>
                    </div>
                    <div className="row quick multi-button custom-multi-button">
                      <div className="col-md-4">
                        <label className="form-label d-block">Last</label>
                        <input
                          type="button"
                          check-val="lastYear"
                          onClick={() => {
                            setDates("lastYear");
                          }}
                          className="generate_report"
                          defaultValue="Year"
                        />
                        <input
                          type="button"
                          check-val="lastQuarter"
                          onClick={() => {
                            setDates("lastQuarter");
                          }}
                          className="generate_report"
                          defaultValue="Quarter"
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="lastMonth"
                          onClick={() => {
                            setDates("lastMonth");
                          }}
                          className="generate_report"
                        />
                        <input
                          type="button"
                          defaultValue="Yesterday"
                          check-val="lastDay"
                          className="generate_report"
                          onClick={() => {
                            setDates("lastDay");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">To-Date</label>
                        <input
                          type="button"
                          defaultValue="Year"
                          check-val="toDateYear"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateYear");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Quarter"
                          check-val="toDateQuarter"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateQuarter");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="toDateMonth"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateMonth");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Today"
                          check-val="toDateToday"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateToday");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">Previous</label>
                        <input
                          type="button"
                          defaultValue="365 Days"
                          check-val="previous365"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous365");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="90 Days"
                          check-val="previous90"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous90");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="30 Days"
                          check-val="previous30"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous30");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="9 Days"
                          check-val="previous9"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous9");
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {/* //added a check that if it is sales login that he cannot generte report more then 3 months before */}

                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12"></div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label fw-bold">
                      Search by Package Type
                    </label>
                    <select
                      className="form-select"
                      name="package_type"
                      value={values.package_type}
                      onChange={(e) => {
                        handleChange(e);
                        getRevenueCategory(e.target.value);
                        values.revenueCat = "";
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="" defaultValue="">
                        All
                      </option>
                      <option value="6">Registration </option>
                      <option value="1">Gym </option>
                      <option value="2">Personal Trainer </option>
                      <option value="3">Guest Pass </option>
                      <option value="4">Small Group PT </option>
                      <option value="16">Befit </option>
                      <option value="15">GX </option>
                      <option value="5">Nutrition </option>
                      <option value="7">Bootcamp </option>
                      <option value="8">Freezing </option>
                      <option value="9">Genral </option>
                      {/* <option value="10">Cafe </option> */}
                      <option value="11">CFT </option>
                      <option value="12">LesMills </option>
                      <option value="13">Cafe Deposits </option>
                      <option value="14">Physiotherapy</option>
                    </select>
                  </div>
                </div>
                {values.package_type !== "3" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label fw-bold">
                        Revenue Category
                      </label>
                      {revenueCategory.length ? (
                        <select
                          className="form-select"
                          name="revenueCat"
                          value={values.revenueCat}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {revenueCategory.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          name="revenueCat"
                          value={values.revenueCat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">All</option>
                        </select>
                      )}
                    </div>
                  </div>
                ) : null}

                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {show ? <Table data={myData} name={myNames} /> : null}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default SalesHistoryreports;
