import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";

export default function MISReport() {
  const [branch, setBranch] = useState(""); // State for branch selection
  const [showReport, setShowReport] = useState(false); // State to show the report
  const [reportData, setReportData] = useState(null); // State to hold the report data
  // const [loading, setLoading] = useState(false); // State for loader

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleDownloadReport = () => {
    const input = document.querySelector(".report-preview");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`MIS_Report_${moment().format("YYYY-MM-DD")}.pdf`);
    });
  };

  const handleGenerateReport = () => {
    // setLoading(true); // Show loader
    UserServices.MISReport(branch)
      .then((response) => {
        const data = response.data;
        setReportData({
          sales: [
            { particulars: "Sales Till Date from month start", qty: data.salem_qty || "--", price: data.salem_price || "--", discount: data.salem_discount || "--", gst: data.salem_gst || "--", netPrice: data.salem_net || "--" },
            { particulars: "Todays Total Sale", qty: data.saleqty_today || "--", price: data.saleprice_today || "--", discount: data.salediscount_today || "--", gst: data.salegst_today || "--", netPrice: data.salenet_today || "--" },
          ],
          footfall: {
            morning: data.morning_SevnToTwlv || "--",
            afternoon: data.after_twlvTofive || "--",
            evening: data.even_fiveToeleven || "--",
            gymEvening: data.gym_evening || "--",
            totalFemales: data.totalFemales || "--",
            totalMales: data.totalMales || "--",
            busiestTimeSlot: data.busiestTimeSlot || "--",
            slowestTimeSlot: data.slowestTimeSlot || "--",
            absentPaidClients: data.absentPaidClients || "--",
            totalStudioAttendance: data.totalStudioAttendance || "--",
            studioAttendanceSession1: data.studioAttendanceSession1 || "--",
            studioAttendanceSession2: data.studioAttendanceSession2 || "--",
            studioAttendanceSession3: data.studioAttendanceSession3 || "--",
            studioAttendanceSession4: data.studioAttendanceSession4 || "--",
            visitorsWalkIns: data.visitorsWalkIns || "--",

          },
          // cafe: {
          //   totalSale: data.totalSale || "--",
          //   totalMeals: data.totalMeals || "--",
          //   totalDrinks: data.totalDrinks || "--",
          //   totalSides: data.totalSides || "--",
          //   totalStaffOrders: data.totalStaffOrders || "--",
          // },
          finance: {
            totalSaleToDate: data.salem_net || "--",
            totalExpensesToDate: data.t_expense_date || "--",
            todaysExpenses: data.today_expense || "--",
            totalExpensesPendingApproval: data.totalExpensesPendingApproval || "--",
          },
          personalTraining: {
            staffPresent: data.staffPresent || "--",
            newUnitsSold: data.newUnitsSold || "--",
            renewed: data.renewed || "--",
            physioSessions: data.physioSessions || "--",
            nutritionistSessions: data.nutritionistSessions || "--",
          },
          hr: {
            totalStaff: data.totalStaff || "--",
            present: data.presentStaff || "--",
            absent: data.absentStaff || "--",
            late: data.lateStaff || "--",
          },
          gx: {
            gxsaleqty_today: data.gxsaleqty_today || "--",
            gxsaleprice_today: data.gxsaleprice_today || "--",
            gxsalediscount_today: data.gxsalediscount_today || "--",
            gxsalegst_today: data.gxsalegst_today || "--",
            gxsalenet_today: data.gxsalenet_today || "--",
          },
          nutrition: {
            nsaleqty_today: data.nsaleqty_today || "--",
            nsaleprice_today: data.nsaleprice_today || "--",
            nsalediscount_today: data.nsalediscount_today || "--",
            nsalegst_today: data.nsalegst_today || "--",
            nsalenet_today: data.nsalenet_today || "--",
          },
          physio: {
            physaleqty_today: data.physaleqty_today || "--",
            physaleprice_today: data.physaleprice_today || "--",
            physalediscount_today: data.physalediscount_today || "--",
            physalegst_today: data.physalegst_today || "--",
            physalenet_today: data.physalenet_today || "--",
          },

          ptnew: {
            ptnsaleqty_today: data.ptnsaleqty_today || "--",
            ptnsaleprice_today: data.ptnsaleprice_today || "--",
            ptnsalediscount_today: data.ptnsalediscount_today || "--",
            ptnsalegst_today: data.ptnsalegst_today || "--",
            ptnsalenet_today: data.ptnsalenet_today || "--",
          },

          ptrenew: {
            ptrsaleqty_today: data.ptrsaleqty_today || "--",
            ptrsaleprice_today: data.ptrsaleprice_today || "--",
            ptrsalediscount_today: data.ptrsalediscount_today || "--",
            ptrsalegst_today: data.ptrsalegst_today || "--",
            ptrsalenet_today: data.ptrsalenet_today || "--",
          },

          gymnew: {
            gnsaleqty_today: data.gnsaleqty_today || "--",
            gnsaleprice_today: data.gnsaleprice_today || "--",
            gnsalediscount_today: data.gnsalediscount_today || "--",
            gnsalegst_today: data.gnsalegst_today || "--",
            gnsalenet_today: data.gnsalenet_today || "--",
          },

          gymrenew: {
            grsaleqty_today: data.grsaleqty_today || "--",
            grsaleprice_today: data.grsaleprice_today || "--",
            grsalediscount_today: data.grsalediscount_today || "--",
            grsalegst_today: data.grsalegst_today || "--",
            grsalenet_today: data.grsalenet_today || "--",
          },

          cft: {
            cftsaleqty_today: data.cftsaleqty_today || "--",
            cftsaleprice_today: data.cftsaleprice_today || "--",
            cftsalediscount_today: data.cftsalediscount_today || "--",
            cftsalegst_today: data.cftsalegst_today || "--",
            cftsalenet_today: data.cftsalenet_today || "--",
          },
          cafe: {
            csaleqty_today: data.csaleqty_today || "--",
            csaleprice_today: data.csaleprice_today || "--",
            csalediscount_today: data.csalediscount_today || "--",
            csalegst_today: data.csalegst_today || "--",
            csalenet_today: data.csalenet_today || "--",
            total_meals: data.total_meals || "--",
            total_drinks: data.total_drinks || "--",
            total_sides: data.total_sides || "--",

          },
          absentLate: data.leaveStaff || [],
          towelsFaults: data.departments || [],
        });
        setShowReport(true); // Show the report preview
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      })
    // .finally(() => {
    //   setLoading(false); // Hide loader
    // });
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">MIS Report</h5>
            </div>
            <div className="card-body">
              {/* Branch Selection */}
              <div className="form-group">
                <label>Select Branch</label>
                <select className="form-control" value={branch} onChange={handleBranchChange}>
                  <option value="">Select Branch</option>
                  <option value="1">G-13</option>
                  <option value="15">F-11</option>
                </select>
              </div>

              {/* Button to generate report */}
              <button className="btn btn-primary mt-3" onClick={handleGenerateReport} disabled={!branch}>
                Go
              </button>

              {/* Display Report Preview */}
              {showReport && (
                <div className="report-preview">
                  <div className="header">
                    {branch === "1" ? "VOSTRO WORLD (G-13) DAILY MIS REPORT" : "VOSTRO WORLD (F-11) DAILY MIS REPORT"}
                  </div>
                  <div className="content">
                    <div className="column">
                      <div className="section">
                        <div className="section-title">SALES</div>
                        <table>
                          <tr>
                            <th>Date:</th>
                            <td colSpan="5">{moment().format("MMMM Do YYYY")}</td>
                          </tr>
                          <tr>
                            <th>Particulars</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>GST</th>
                            <th>Net Price</th>
                          </tr>
                          {reportData.sales.map((item, index) => (
                            <tr key={index}>
                              <td>{item.particulars}</td>
                              <td>{item.qty}</td>
                              <td>{item.price}</td>
                              <td>{item.discount}</td>
                              <td>{item.gst}</td>
                              <td>{item.netPrice}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div class="section-title">DAILY SALES BREAKUP</div>
                        <table>
                          <tr>
                            <th>Particulars</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>GST</th>
                            <th>Net Price</th>
                          </tr>
                          <tr><td>Gym New</td><td>{reportData?.gymnew?.gnsaleqty_today}</td><td>{reportData?.gymnew?.gnsaleprice_today}</td><td>{reportData?.gymnew?.gnsalediscount_today}</td><td>{reportData?.gymnew?.gnsalegst_today}</td><td>{reportData?.gymnew?.gnsalenet_today}</td></tr>
                          <tr><td>Gym Existing</td><td>{reportData?.gymrenew?.grsaleqty_today}</td><td>{reportData?.gymrenew?.grsaleprice_today}</td><td>{reportData?.gymrenew?.grsalediscount_today}</td><td>{reportData?.gymrenew?.grsalegst_today}</td><td>{reportData?.gymrenew?.grsalenet_today}</td></tr>
                          <tr><td>Personal Training New</td><td>{reportData?.ptnew?.ptnsaleqty_today}</td><td>{reportData?.ptnew?.ptnsaleprice_today}</td><td>{reportData?.ptnew?.ptnsalediscount_today}</td><td>{reportData?.ptnew?.ptnsalegst_today}</td><td>{reportData?.ptnew?.ptnsalenet_today}</td></tr>
                          <tr><td>Personal Training ReNew</td><td>{reportData?.ptrenew?.ptrsaleqty_today}</td><td>{reportData?.ptrenew?.ptrsaleprice_today}</td><td>{reportData?.ptrenew?.ptrsalediscount_today}</td><td>{reportData?.ptrenew?.ptrsalegst_today}</td><td>{reportData?.ptrenew?.ptrsalenet_today}</td></tr>
                          <tr><td>Gx Studio</td><td>{reportData?.gx?.gxsaleqty_today}</td><td>{reportData?.gx?.gxsaleprice_today}</td><td>{reportData?.gx?.gxsalediscount_today}</td><td>{reportData?.gx?.gxsalegst_today}</td><td>{reportData?.gx?.gxsalenet_today}</td></tr>
                          <tr><td>Nutrition</td><td>{reportData?.nutrition?.nsaleqty_today}</td><td>{reportData?.nutrition?.nsaleprice_today}</td><td>{reportData?.nutrition?.nsalediscount_today}</td><td>{reportData?.nutrition?.nsalegst_today}</td><td>{reportData?.nutrition?.nsalenet_today}</td></tr>
                          <tr><td>Physio</td><td>{reportData?.physio?.physaleqty_today}</td><td>{reportData?.physio?.physaleprice_today}</td><td>{reportData?.physio?.physalediscount_today}</td><td>{reportData?.physio?.physalegst_today}</td><td>{reportData?.physio?.physalenet_today}</td></tr>
                          <tr><td>Academy</td><td>{reportData?.cft?.cftsaleqty_today}</td><td>{reportData?.cft?.cftsaleprice_today}</td><td>{reportData?.cft?.cftsalediscount_today}</td><td>{reportData?.cft?.cftsalegst_today}</td><td>{reportData?.cft?.cftsalenet_today}</td></tr>
                          <tr><td>Cafe</td><td>{reportData?.cafe?.csaleqty_today}</td><td>{reportData?.cafe?.csaleprice_today}</td><td>{reportData?.cafe?.csalediscount_today}</td><td>{reportData?.cafe?.csalegst_today}</td><td>{reportData?.cafe?.csalenet_today}</td></tr>
                          <tr><td>Total</td><td colspan="5"></td></tr>
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">FOOTFALL</div>
                        <table>
                          <tr>
                            <td>Morning 7:00 am to 12:00 pm</td>
                            <td>{reportData?.footfall?.morning}</td>
                          </tr>
                          <tr>
                            <td>Afternoon 12:00 pm to 5:00 pm</td>
                            <td>{reportData?.footfall?.afternoon}</td>
                          </tr>
                          <tr>
                            <td>Evening 5:00 pm to 11:00 pm</td>
                            <td>{reportData?.footfall?.evening}</td>
                          </tr>
                          <tr>
                            <td>Total Females</td>
                            <td>{reportData?.footfall?.totalFemales}</td>
                          </tr>
                          <tr>
                            <td>Total Male</td>
                            <td>{reportData?.footfall?.totalMales}</td>
                          </tr>
                          <tr>
                            <td>Busiest Time Slot</td>
                            <td>{reportData?.footfall?.busiestTimeSlot}</td>
                          </tr>
                          <tr>
                            <td>Slowest Time Slot</td>
                            <td>{reportData?.footfall?.slowestTimeSlot}</td>
                          </tr>
                          <tr>
                            <td>Absent Paid Clients</td>
                            <td>{reportData?.footfall?.absentPaidClients}</td>
                          </tr>
                          <tr>
                            <td>Total Studio Attendance</td>
                            <td>{reportData?.footfall?.totalStudioAttendance}</td>
                          </tr>
                          <tr>
                            <td>Studio Attendance Session 1 (11:00 AM)</td>
                            <td>{reportData?.footfall?.studioAttendanceSession1}</td>
                          </tr>
                          <tr>
                            <td>Studio Attendance Session 2 (05:15 AM)</td>
                            <td>{reportData?.footfall?.studioAttendanceSession2}</td>
                          </tr>
                          <tr>
                            <td>Studio Attendance Session 3 (06:30 AM)</td>
                            <td>{reportData?.footfall?.studioAttendanceSession3}</td>
                          </tr>
                          <tr>
                            <td>Studio Attendance Session 4 (07:30 AM)</td>
                            <td>{reportData?.footfall?.studioAttendanceSession4}</td>
                          </tr>
                          <tr>
                            <td>Visitors/Walk-Ins</td>
                            <td>{reportData?.footfall?.visitorsWalkIns}</td>
                          </tr>
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">CAFÉ REPORT</div>
                        <table>
                          <tr>
                            <td>Total Sale</td>
                            <td>{reportData?.cafe?.csalenet_today}</td>
                          </tr>
                          <tr>
                            <td>Total Meals Sold</td>
                            <td>{reportData?.cafe?.total_meals}</td>
                          </tr>
                          <tr>
                            <td>Total Drinks</td>
                            <td>{reportData.cafe.total_drinks}</td>
                          </tr>
                          <tr>
                            <td>Total Sides</td>
                            <td>{reportData.cafe.total_sides}</td>
                          </tr>
                          <tr>
                            <td>Total Staff Orders</td>
                            <td>{reportData.cafe.totalStaffOrders}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="column">
                      <div className="section">
                        <div className="section-title">FINANCE</div>
                        <table>
                          <tr>
                            <td>Total Sale to Date</td>
                            <td>{reportData.finance.totalSaleToDate}</td>
                          </tr>
                          <tr>
                            <td>Total Expenses to Date</td>
                            <td>{reportData.finance.totalExpensesToDate}</td>
                          </tr>
                          <tr>
                            <td>Today's Expenses</td>
                            <td>{reportData.finance.todaysExpenses}</td>
                          </tr>
                          <tr>
                            <td>Total Expenses Pending Approval</td>
                            <td>{reportData.finance.totalExpensesPendingApproval}</td>
                          </tr>
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">PERSONAL TRAINING</div>
                        <table>
                          <tr>
                            <th>PT Staff Present</th>
                            <td>{reportData.personalTraining.staffPresent}</td>
                          </tr>
                          <tr>
                            <th>New PT Units Sold</th>
                            <td>{reportData?.ptnew?.ptnsaleqty_today}</td>
                          </tr>
                          <tr>
                            <th>PT Renewed</th>
                            <td>{reportData?.ptrenew?.ptrsaleqty_today}</td>
                          </tr>
                          <tr>
                            <th>Physio Sessions Conducted</th>
                            <td>{reportData?.physio?.physaleqty_today}</td>
                          </tr>
                          <tr>
                            <th>Nutritionist Sessions Conducted</th>
                            <td>{reportData?.nutrition?.nsaleqty_today}</td>
                          </tr>
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">HR REPORT</div>
                        <table>
                          <tr>
                            <th>Total Staff</th>
                            <td>{reportData.hr.totalStaff}</td>
                          </tr>
                          <tr>
                            <th>Present</th>
                            <td>{reportData.hr.present}</td>
                          </tr>
                          <tr>
                            <th>Absent</th>
                            <td>{reportData.hr.absent}</td>
                          </tr>
                          <tr>
                            <th>Late</th>
                            <td>{reportData.hr.late}</td>
                          </tr>
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">ABSENT / LATE</div>
                        <table>
                          <tr>
                            <th>Department</th>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Name</th>
                          </tr>
                          {reportData.absentLate.map((item, index) => (
                            <tr key={index}>
                              <td>{item.department}</td>
                              <td>{item.name}</td>
                              <td>{item.department}</td>
                              <td>{item.name}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                      <br></br>

                      <div className="section">
                        <div className="section-title">TOWELS / FAULTS</div>
                        <table>
                          <tr>
                            <th>In</th>
                            <th>Out</th>
                            <th>Department</th>
                            <th>Fault</th>
                          </tr>
                          {reportData.towelsFaults.map((item, index) => (
                            <tr key={index}>
                              <td>{item.in}</td>
                              <td>{item.out}</td>
                              <td>{item.department}</td>
                              <td>{item.fault}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>

                  {/* Download Button */}
                  <button className="btn btn-secondary mt-3" onClick={handleDownloadReport}>
                    Download Report
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <style>{`
        .report-preview {
          border: 1px solid #ccc;
          padding: 20px;
          margin-top: 20px;
        }
        .report-preview table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }
        .report-preview th, .report-preview td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        .report-preview h2, .report-preview h3 {
          margin-top: 0;
        }
        .header {
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          margin-bottom: 20px;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
        }
        .column {
          width: 50%;
          padding: 10px;
        }
        .section {
          border: 1px solid #ccc;
          margin-bottom: 10px;
        }
        .section-title {
          background: #d9d9d9;
          padding: 8px;
          font-weight: bold;
          text-align: center;
        }
          .loader {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.5em;
          color: #007bff;
        }
      `}</style>
    </div>
  );
}
